import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SupportedLanguages } from '@as/api';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../services/locale.service';

export interface Language {
  code: SupportedLanguages;
  name: string;
}
@Component({
  selector: 'lib-language-switcher',
  templateUrl: 'language-switcher.component.html',
  standalone: true,
  styleUrls: ['language-switcher.component.scss'],
  imports: [CommonModule, TranslateModule, NgbModule, NgbDropdown],
})
export class LanguageSwitcherComponent {
  public availableLanguages: Language[] = [
    {
      code: SupportedLanguages.PL,
      name: 'Polski',
    },
    {
      code: SupportedLanguages.EN,
      name: 'English',
    },
    {
      code: SupportedLanguages.IT,
      name: 'Italiano',
    },
    {
      code: SupportedLanguages.ES,
      name: 'Español',
    },
  ];

  public currentLang!: Language;

  constructor(
    private readonly translateService: TranslateService,
    private readonly localeService: LocaleService,
  ) {
    const language = this.getDefaultOrStorageLang();
    this.currentLang = language;
    this.translateService.use(this.currentLang.code);
    this.localeService.setLocale(language.code);
  }

  public setLanguage(code: string) {
    this.translateService.use(code);
    localStorage.setItem('language', code);
    this.currentLang = this.availableLanguages.find(language => language.code === code) || this.currentLang;
    this.localeService.setLocale(this.currentLang.code);
  }

  private getDefaultOrStorageLang() {
    const storageValue = localStorage.getItem('language');
    const storageLanguage = this.availableLanguages.find(x => x.code === storageValue);

    const langToSet =
      storageLanguage !== undefined
        ? storageLanguage
        : this.availableLanguages.find(language => language.code === this.translateService.getDefaultLang()) || this.availableLanguages[0];

    return langToSet;
  }
}
