<div
  ngbDropdown
  class="menu-nav-language-switcher__container d-inline-block">
  <button
    type="button"
    class="menu-nav-language-switcher__btn btn d-flex align-items-center"
    id="menuNavLanguageDropDown"
    ngbDropdownToggle>
    <div class="icon-container">
      <i [ngClass]="['flag-icon', 'flag-icon-' + currentLang.code]"></i>
    </div>
  </button>
  <div
    class="menu-nav-language-switcher__dropdown-menu"
    ngbDropdownMenu
    aria-labelledby="menuNavLanguageDropDown">
    <ng-container *ngFor="let language of availableLanguages; let last = last">
      <button
        type="button"
        class="menu-nav-language-switcher__menu-btn"
        ngbDropdownItem
        (click)="setLanguage(language.code)">
        <i
          class="menu-nav-language-switcher__menu-icon"
          [ngClass]="['flag-icon', 'flag-icon-item', 'flag-icon-' + language.code]"></i>
        {{ language.name }}
      </button>
      <div
        *ngIf="!last"
        class="dropdown-divider"></div>
    </ng-container>
  </div>
</div>
