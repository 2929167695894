import { Injectable } from '@angular/core';
import { AppConfiguration } from '../models/app-configuration';
import { firstValueFrom, from, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  public configuration: Partial<AppConfiguration> = {};

  public init() {
    return firstValueFrom(from(fetch('assets/app-config.json').then(response => response.json())).pipe(tap(x => (this.configuration = x))));
  }
}
